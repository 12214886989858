import React, { useState, Fragment, useEffect } from "react";
import { connect } from "react-redux";
import useInterval from "../../../../hooks/useInterval";
import { useTranslation } from "react-i18next";
import useResizeAware from "react-resize-aware";

import css from "./Carriers.module.css";
import * as actionTypes from "../../../../store/actions";

import CarrierButton from "./CarrierButton/CarrierButton";
import CarrierBox from "./CarrierBox/CarrierBox";
import Button from "../../../UI/Button/Button";
import ErrorMessage from "../../../UI/ErrorMessage/ErrorMessage";

import canadapostLogo from "../../../../assets/images/logo-canada-post.svg";
import purolatorLogo from "../../../../assets/images/logo-purolator.svg";
import fedexLogo from "../../../../assets/images/logo-fedex.svg";
import upsLogo from "../../../../assets/images/logo-ups.svg";
import glsLogo from "../../../../assets/images/logo-gls.png";
import dragonflyLogo from "../../../../assets/images/logo-dragonfly.png";
import obiboxLogo from "../../../../assets/images/logo-obibox.png";
import nationexLogo from "../../../../assets/images/logo-nationex.png";
import icsLogo from "../../../../assets/images/logo-ics.png";
import canparexpressLogo from "../../../../assets/images/logo-canparexpress.png";
import loomisexpressLogo from "../../../../assets/images/logo-loomisexpress.png";
import delivroLogo from "../../../../assets/images/logo-delivro.png";
import uspsLogo from "../../../../assets/images/logo-usps.png";
import dpdLogo from "../../../../assets/images/logo-dpd.png";
import colissimoLogo from "../../../../assets/images/logo-colissimo.png";
import chronopostLogo from "../../../../assets/images/logo-chronopost.png";
import swyftLogo from "../../../../assets/images/logo-swyft.png";
import laposteLogo from "../../../../assets/images/logo-laposte.png";
import goforLogo from "../../../../assets/images/logo-gofor.png";
import tforcelogisticsLogo from "../../../../assets/images/logo-tforcelogistics.png";
import goboltLogo from "../../../../assets/images/logo-gobolt.png";
import flashboxLogo from "../../../../assets/images/logo-flashbox.png";
import dayandrossLogo from "../../../../assets/images/logo-dayandross.png";
import metrolandLogo from "../../../../assets/images/logo-metroland.png";
import landmarkglobalLogo from "../../../../assets/images/logo-landmarkglobal.png";
import uniuniLogo from "../../../../assets/images/logo-uniuni.png";
import fleelopticsLogo from "../../../../assets/images/logo-fleeloptics.png";
import envoiLogo from "../../../../assets/images/logo-envoi.png";
import pigeonshipLogo from "../../../../assets/images/logo-pigeonship.png";
import cirroLogo from "../../../../assets/images/logo-cirro.png";
import fastrateLogo from "../../../../assets/images/logo-fastrate.png";
import wizmoLogo from "../../../../assets/images/logo-wizmo.png";
import stallionexpressLogo from "../../../../assets/images/logo-stallionexpress.png";
import eshipperLogo from "../../../../assets/images/logo-eshipper.png";
import tyitgoLogo from "../../../../assets/images/logo-tyitgo.png";
import xpoLogo from "../../../../assets/images/logo-xpo.png";
import pitneybowesLogo from "../../../../assets/images/logo-pitneybowes.png";
import joeycoLogo from "../../../../assets/images/logo-joeyco.png";
import groupeguilbaultLogo from "../../../../assets/images/logo-groupeguilbault.png";
import gylmirLogo from "../../../../assets/images/logo-gylmir.png";
import straightlineLogo from "../../../../assets/images/logo-straightline.png";
import transportgmrLogo from "../../../../assets/images/logo-transportgmr.png";

const carriers = (props) => {
  const { t, i18n } = useTranslation("signup");
  const [resizeListener, sizes] = useResizeAware();
  const [carriersConfig] = useState([
    {
      name: "canadapost",
      title: t("carriers.canadapost.title", "Canada Post"),
      logo: canadapostLogo,
    },
    {
      name: "dragonfly",
      title: t("carriers.dragonfly.title", "Intelcom / Dragonfly"),
      logo: dragonflyLogo,
    },
    {
      name: "obibox",
      title: t("carriers.obibox.title", "Obibox"),
      logo: obiboxLogo,
    },
    {
      name: "nationex",
      title: t("carriers.nationex.title", "Nationex"),
      logo: nationexLogo,
    },
    {
      name: "ics",
      title: t("carriers.ics.title", "ICS"),
      logo: icsLogo,
    },
    {
      name: "canparexpress",
      title: t("carriers.canparexpress.title", "Canpar Express"),
      logo: canparexpressLogo,
    },
    {
      name: "loomisexpress",
      title: t("carriers.loomisexpress.title", "Loomis Express"),
      logo: loomisexpressLogo,
    },
    {
      name: "delivro",
      title: t("carriers.delivro.title", "Delivro"),
      logo: delivroLogo,
    },
    {
      name: "usps",
      title: t("carriers.usps.title", "USPS"),
      logo: uspsLogo,
    },
    {
      name: "dpd",
      title: t("carriers.dpd.title", "dpd"),
      logo: dpdLogo,
    },
    {
      name: "colissimo",
      title: t("carriers.colissimo.title", "colissimo"),
      logo: colissimoLogo,
    },
    {
      name: "chronopost",
      title: t("carriers.chronopost.title", "chronopost"),
      logo: chronopostLogo,
    },
    {
      name: "swyft",
      title: t("carriers.swyft.title", "Swyft"),
      logo: swyftLogo,
    },
    {
      name: "laposte",
      title: t("carriers.laposte.title", "La Poste"),
      logo: laposteLogo,
    },
    {
      name: "gls",
      title: t("carriers.gls.title", "GLS"),
      logo: glsLogo,
    },
    {
      name: "transportgmr",
      title: t("carriers.transportgmr.title", "Transport GMR"),
      logo: transportgmrLogo,
    },
    {
      name: "straightline",
      title: t("carriers.straightline.title", "Straightline"),
      logo: straightlineLogo,
    },
    {
      name: "gylmir",
      title: t("carriers.gylmir.title", "Gylmir"),
      logo: gylmirLogo,
    },
    {
      name: "groupeguilbault",
      title: t("carriers.groupeguilbault.title", "Groupe Guilbault"),
      logo: groupeguilbaultLogo,
    },
    {
      name: "joeyco",
      title: t("carriers.joeyco.title", "JoeyCo"),
      logo: joeycoLogo,
    },
    {
      name: "pitneybowes",
      title: t("carriers.pitneybowes.title", "pitney bowes"),
      logo: pitneybowesLogo,
    },
    {
      name: "xpo",
      title: t("carriers.xpo.title", "XPO"),
      logo: xpoLogo,
    },
    {
      name: "tyitgo",
      title: t("carriers.tyitgo.title", "TyltGo"),
      logo: tyitgoLogo,
    },
    {
      name: "eshipper",
      title: t("carriers.eshipper.title", "eShipper"),
      logo: eshipperLogo,
    },
    {
      name: "stallionexpress",
      title: t("carriers.stallionexpress.title", "Stallion Express"),
      logo: stallionexpressLogo,
    },
    {
      name: "wizmo",
      title: t("carriers.wizmo.title", "Wizmo"),
      logo: wizmoLogo,
    },
    {
      name: "fastrate",
      title: t("carriers.fastrate.title", "FastRate"),
      logo: fastrateLogo,
    },
    {
      name: "cirro",
      title: t("carriers.cirro.title", "CIRRO"),
      logo: cirroLogo,
    },
    {
      name: "pigeonship",
      title: t("carriers.pigeonship.title", "PigeonShip"),
      logo: pigeonshipLogo,
    },
    {
      name: "envoi",
      title: t("carriers.envoi.title", "envoi"),
      logo: envoiLogo,
    },
    {
      name: "fleeloptics",
      title: t("carriers.fleeloptics.title", "FleelOptics"),
      logo: fleelopticsLogo,
    },
    {
      name: "uniuni",
      title: t("carriers.uniuni.title", "uniuni"),
      logo: uniuniLogo,
    },
    {
      name: "landmarkglobal",
      title: t("carriers.landmarkglobal.title", "landmark global"),
      logo: landmarkglobalLogo,
    },
    {
      name: "metroland",
      title: t("carriers.metroland.title", "metroland"),
      logo: metrolandLogo,
    },
    {
      name: "dayandross",
      title: t("carriers.dayandross.title", "Day and Ross"),
      logo: dayandrossLogo,
    },
    {
      name: "flashbox",
      title: t("carriers.flashbox.title", "FlashBox"),
      logo: flashboxLogo,
    },
    {
      name: "gobolt",
      title: t("carriers.gobolt.title", "GoBolt"),
      logo: goboltLogo,
    },
    {
      name: "tforcelogistics",
      title: t("carriers.tforcelogistics.title", "TForce Logistics"),
      logo: tforcelogisticsLogo,
    },
    {
      name: "gofor",
      title: t("carriers.gofor.title", "go / for"),
      logo: goforLogo,
    },
    {
      name: "purolator",
      title: t("carriers.purolator.title", "Purolator"),
      logo: purolatorLogo,
    },
    {
      name: "fedex",
      title: t("carriers.fedex.title", "FedEx"),
      logo: fedexLogo,
    },
    {
      name: "ups",
      title: t("carriers.ups.title", "UPS"),
      logo: upsLogo,
    },
  ]);
  const [error, setError] = useState("");
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    // Push event to Google Analytics
    window.dataLayer.push({
      event: "virtual_pageview",
      virtualPageURL: `/virtual/${i18n.language}/step2`,
    });
  }, []);

  useInterval(() => {
    const connectedCarriers =
      props.carriers.filter((carrier) => carrier.step === "done").length > 0;
    if (connectedCarriers) setConnected(true);
  }, 200);

  // Carrier boxes
  let carrierBoxes = null;
  if (props.carriers.length) {
    carrierBoxes = props.carriers.map((carrier) => {
      return (
        <CarrierBox
          key={carrier.key}
          carrier={carrier}
          remove={() => props.removeCarrier(carrier.key)}
        />
      );
    });
  }

  // Carrier buttons
  const carrierBoxOpened = props.carriers.filter((carrier) => carrier.step !== "done").length;
  const carrierButtons =
    !carrierBoxOpened &&
    carriersConfig.map((carrier) => {
      return (
        <CarrierButton
          key={carrier.name}
          imgSrc={carrier.logo}
          addCarrier={() => props.addCarrier(carrier)}
        />
      );
    });

  const submit = () => {
    // Validation
    let error = null;

    // Check if at least one carrier is connected
    if (props.carriers.length > 0) {
      const connectedCarriers = props.carriers.filter((carrier) => carrier.step === "done");
      if (!connectedCarriers.length) {
        error = t("carriers.atLeastOne", "You must connect at least one carrier.");
      }
    } else {
      error = t("carriers.atLeastOne", "You must connect at least one carrier.");
    }

    if (error) {
      setError(error);
      return;
    }

    setError("");

    props.setCurrentStep("confirmation");
  };

  // Error message
  let errorMessage = null;
  if (error) {
    errorMessage = <ErrorMessage>{error}</ErrorMessage>;
  }

  return (
    <Fragment>
      {resizeListener}
      <div className={css.Carriers}>
        <h1>{t("carriers.title", "Connect your carriers")}</h1>

        {errorMessage}

        {carrierBoxes}

        {carrierButtons ? (
          <>
            <h2>{t("carriers.addCarriers", "Add Carriers")}</h2>
            <div className={css.CarrierButtons}>{carrierButtons}</div>
          </>
        ) : null}

        {/* {carrierBoxes ? <h2>{t('carriers.selectedCarriers', 'My Selected Carriers')}</h2> : null} */}
      </div>

      {connected ? (
        <div className={css.StickyAction} style={{ width: sizes.width }}>
          <Button onClick={submit}>{t("carriers.submit", "Continue")}</Button>
        </div>
      ) : null}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.token,
    carriers: state.carriers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addCarrier: (carrier) => dispatch({ type: actionTypes.ADD_CARRIER, carrier: carrier }),
    setCarrierId: (key, id) => dispatch({ type: actionTypes.SET_CARRIER_ID, key: key, id: id }),
    setCarrierProps: (key, props) =>
      dispatch({ type: actionTypes.SET_CARRIER_PROPS, key: key, props: props }),
    removeCarrier: (key) => dispatch({ type: actionTypes.REMOVE_CARRIER, key: key }),
    setCurrentStep: (step) => dispatch({ type: actionTypes.SET_CURRENT_STEP, step: step }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(carriers);
