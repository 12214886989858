import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import css from "./Account.module.css";
import axios from "../../../../axios-bf";
import * as actionTypes from "../../../../store/actions";

import Form from "../../../UI/Form/Form";
import Input from "../../../UI/Input/Input";
import Button from "../../../UI/Button/Button";
import ErrorMessage from "../../../UI/ErrorMessage/ErrorMessage";

const dev =
  window.location.href.indexOf("dev") > 0 || window.location.href.indexOf("localhost") > 0;

const Account = (props) => {
  const { t, i18n } = useTranslation("signup");
  const { path } = useParams();
  const [accountInfo, setAccountInfo] = useState(
    dev
      ? {
          firstName: "Mickael",
          lastName: "Le Riche",
          business: "AlloMat",
          email: "mleriche-bfsignup" + Math.round(Math.random() * 100) + "@gmail.com",
          phone: "514-555-5555",
        }
      : {}
  );
  const [error, setError] = useState("");
  const [errorFields, setErrorFields] = useState({});

  useEffect(() => {
    // Push event to Google Analytics
    window.dataLayer.push({
      event: "virtual_pageview",
      virtualPageURL: `/virtual/${i18n.language}/step1`,
    });
  }, []);

  const onChange = (event) => {
    setAccountInfo({
      ...accountInfo,
      [event.target.name]: event.target.value,
    });
    setErrorFields({
      ...errorFields,
      [event.target.name]: false,
    });
  };

  const submit = () => {
    // Validation
    const errors = [];
    const fields = [];
    const validEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!accountInfo.firstName || !accountInfo.firstName.trim()) {
      errors.push(t("common.firstName", "First Name"));
      fields.push("firstName");
    }
    if (!accountInfo.lastName || !accountInfo.lastName.trim()) {
      errors.push(t("common.lastName", "Last Name"));
      fields.push("lastName");
    }
    if (!accountInfo.email || !validEmail.test(accountInfo.email.trim())) {
      errors.push(t("common.email", "Email"));
      fields.push("email");
    }
    if (!accountInfo.phone || !accountInfo.phone.trim()) {
      errors.push(t("common.phone", "Phone"));
      fields.push("phone");
    }

    if (errors.length) {
      let messageStart = t("common.validField", "Please enter a valid") + " ";
      if (errors.length > 1) messageStart = t("common.validFields", "Please enter valid:") + " ";
      setError(messageStart + errors.join(", ") + ".");
      setErrorFields(Object.assign({ errorFields }, ...fields.map((field) => ({ [field]: true }))));
      return;
    }

    setError("");

    // Send request to API
    const account = {
      lang: i18n.language,
      referrer: props.referrer,
      firstname: accountInfo.firstName,
      lastname: accountInfo.lastName,
      business: accountInfo.business,
      email: accountInfo.email,
      phone: accountInfo.phone,
    };
    axios
      .post("/subscribe/account-trial", account)
      .then((res) => {
        // Handle error message
        if (res.data && res.data.error) {
          setError(res.data.error);
          if (res.data.error.indexOf("email") > 0) {
            setErrorFields(Object.assign({ ...errorFields }, { email: true }));
          }
        }
        // Handle success
        else if (res.data && res.data.created) {
          const token = res.data.token;
          props.setToken(token);
          props.setCurrentStep(path === "club" ? "clubInfo" : "carriers");
        }
        // Unexpected error
        else {
          setError(t("common.unexpectedError", "We are sorry but an unexpected error occured."));
        }
      })
      .catch((error) => {
        // Connexion error
        setError(error.message);
      });
  };

  // Handle error message
  let errorMessage = null;
  if (error) {
    errorMessage = <ErrorMessage>{error}</ErrorMessage>;
  }

  return (
    <>
      <div className={css.Account}>
        <h1>{t("account.title", "Account Information")}</h1>
        {/* <p>{t('account.noCredit', 'No credit card required for the trial')}</p> */}
        <div className={css.Forms}>
          <Form>
            {errorMessage}
            <Input
              name="firstName"
              placeholder={t("common.firstName", "First Name")}
              half
              onChange={onChange}
              value={accountInfo.firstName}
              error={errorFields.firstName}
            />

            <Input
              name="lastName"
              placeholder={t("common.lastName", "Last Name")}
              half
              onChange={onChange}
              value={accountInfo.lastName}
              error={errorFields.lastName}
            />
            <Input
              name="business"
              placeholder={t("common.business", "Business")}
              onChange={onChange}
              value={accountInfo.business}
              error={errorFields.business}
            />
            <Input
              name="email"
              placeholder={t("common.email", "Email")}
              onChange={onChange}
              value={accountInfo.email}
              error={errorFields.email}
            />
            <Input
              name="phone"
              placeholder={t("common.phone", "Phone")}
              onChange={onChange}
              value={accountInfo.phone}
              error={errorFields.phone}
            />
          </Form>
        </div>
        <div className={css.Next}>
          <Button onClick={submit}>{t("account.submit", "Setup my account")}</Button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    referrer: state.referrer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentStep: (step) => dispatch({ type: actionTypes.SET_CURRENT_STEP, step: step }),
    setToken: (token) => dispatch({ type: actionTypes.SET_TOKEN, token: token }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
